import { ref } from "vue";
import { defineStore } from "pinia";

const useUserProfileStore = defineStore(
  "userProfile",
  () => {
    // 用户信息
    const userInfo: any = ref({});
    // tab 栏是否为展开状态
    const isExpand = ref(true);

    const setInfo = (data: any) => {
      userInfo.value = data;
    };

    const getInfo = () => {
      return userInfo.value;
    };

    const setExpand = (data: boolean) => {
      isExpand.value = data;
    };

    return {
      userInfo,
      setInfo,
      getInfo,
      isExpand,
      setExpand,
    };
  },
  {
    // 开启数据缓存
    persist: {
      key: "lizigo_userProfile",
    },
  }
);

export default useUserProfileStore;
